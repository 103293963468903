.login {
  height: 100vh;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogin {
  font-weight: bold;
  text-align: center;
  font-size: 25px;
}


.backgraundLogin{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/Assets/img/promocion.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}


.boxLogin{
  background-color: white;
  height: 350px;
  width: 450px;
}


.appNameLogin{
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 25px;
}

.descriptionLogin{
  padding: 19px;
    text-align: justify;
}

.buttonLogin{
  padding: 0px 20px 0px 20px !important;
}